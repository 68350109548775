/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container, Inline, Modal, Typography, Row,
} from '@airbus/components-react';
import './ModalPopup.scss';
import { Close, LiveHelp } from '@airbus/icons/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import {
  loadCheckerAcSubTypeOptions,
  loadCheckerAcTypeOptions,
  loadCheckerMsnOptions,
  registerPostHogProperties,
} from '../../../utils/commonUtil';
import {
  loadOperators,
} from '../../../models/popupFilterModel/popupFilterThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import {
  setModalToggle,
} from '../../../models/popupFilterModel/popupFilterSlice';
import {
  POPUP_TITLE, RESET, VALIDATE, POPUP_WARNING_TEXT, BROWSER, CHECKER,
} from '../../../utils/constants';
import { setHelpModelToggle } from '../../../models/helpModel/helpModelSlice';
import { enableValidateButton } from '../../../utils/commonMethods/modalPopupMethods';
import ModSbField from './Field/ModSbField';
import PartField from './Field/PartField';
import DataSourceField from './Field/DataSourceField';
import CommanField from './Field/CommanField';
import CheckerDetailsField from './Field/CheckerDetailsField';
import { setLocalStore, setValidCheckerValue } from '../../../utils/commonMethods/generalMethods';

const initFilterConfig: FilterKeys = {
  operator_name: '',
  aircraft_type: [],
  selected_msn: [],
  selected_sb: [],
  selected_mod: [],
  selected_dataSource: '',
  selected_fin: [],
  selected_pnr: [],
  aircraft_family: '',
  aircraft_subtype: [],
  selected_ata: [],
};

interface Modalprops {
  filterProps: any,
}
const ModalPopup = function ModalPopup(props: Modalprops) {
  const {
    filterProps,
  } = props;
  const [currentFilterConfig, setCurrentFilterConfig] = React.useState<FilterKeys | undefined>(initFilterConfig);
  const [aircraftTypeValue, setAircraftTypeValue] = React.useState([]);
  const [operatorValue, setOperatorValue] = React.useState('');
  const checkerState = useAppSelector((state) => state.checkerFleet);
  const [reset, setReset] = useState(false);
  const dispatch = useAppDispatch();
  const selectedBrowserTab = useAppSelector((state) => state.app.selectedTab);
  const popupFilterState = useAppSelector((state) => state.popUpFilter);
  const checkerDetailsState = useAppSelector((state) => state.displayCheckerDetailsTable);
  useEffect(() => {
    if (filterProps.sourceTab === BROWSER) dispatch(loadOperators()());
  }, [dispatch, filterProps.sourceTab]);
  useEffect(() => {
    if (!_.isEmpty(filterProps.selectedFilterState.operator_name)) setCurrentFilterConfig(filterProps.selectedFilterState);
  }, [popupFilterState.modalToggle, filterProps.selectedFilterState]);
  const handleClosePopup = () => {
    registerPostHogProperties({
      ICAO_CODE: filterProps.selectedFilterState?.operator_name?.toString(),
    });
    if (filterProps.sourceTab === CHECKER) {
      loadCheckerAcTypeOptions(
        checkerDetailsState.fleet_detail_filter_selected.operator_name,
        null,
        dispatch,
        checkerState.data[
          checkerDetailsState.fleet_detail_filter_selected.operator_name
        ],
      );
      const selectedAcType = setValidCheckerValue(
        checkerDetailsState.fleet_detail_filter_selected?.operator_name,
        checkerDetailsState.fleet_detail_filter_selected?.aircraft_type?.toString(),
        checkerDetailsState.checkerDetailFiltersSelected?.aircraft_type?.toString(),
        checkerDetailsState.ifOperatorChanged,
      );
      const selectedSubType = setValidCheckerValue(
        checkerDetailsState.fleet_detail_filter_selected?.operator_name,
        checkerDetailsState.fleet_detail_filter_selected?.aircraft_subtype,
        checkerDetailsState.checkerDetailFiltersSelected?.aircraft_subtype,
        checkerDetailsState.ifOperatorChanged,
        checkerDetailsState.ifAircraftTypeChanged,
      );
      loadCheckerAcSubTypeOptions(
        checkerDetailsState.fleet_detail_filter_selected.operator_name,
        '',
        selectedAcType,
        dispatch,
        checkerState.data[
          checkerDetailsState.fleet_detail_filter_selected.operator_name
        ],
      );
      loadCheckerMsnOptions(
        checkerDetailsState.fleet_detail_filter_selected.operator_name,
        '',
        selectedAcType,
        selectedSubType,
        dispatch,
        checkerState.data[
          checkerDetailsState.fleet_detail_filter_selected.operator_name
        ],
      );
    }
    setCurrentFilterConfig(filterProps.selectedFilterState);
    dispatch(setModalToggle({ modalToggle: false }));
    setLocalStore('showFilteredTable', JSON.stringify(true));
  };
  useEffect(() => {
    if (popupFilterState && popupFilterState.operators_list?.length) {
      const listOfOperators = popupFilterState.operators_list[0];
      setCurrentFilterConfig(() => {
        return {
          aircraft_type: [],
          operator_name: listOfOperators,
          aircraft_subtype: [],
          selected_mod: [],
          selected_sb: [],
          selected_msn: [],
          selected_dataSource: '',
          selected_fin: [],
          selected_pnr: [],
        };
      });
      setOperatorValue(popupFilterState.operators_list[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupFilterState.operators_list, selectedBrowserTab]);
  const handleValidate = () => {
    filterProps.handleValidate(currentFilterConfig, filterProps.dispatcher, filterProps.apiDispatcher, filterProps.updateColumnFilterState, filterProps.updateColumnFilterPayload, filterProps.tableState, filterProps.additionalMethods);
    setLocalStore('showFilteredTable', JSON.stringify(true));
  };

  useEffect(() => {
    setCurrentFilterConfig(initFilterConfig);
    setReset(false);
  }, [reset]);

  const modsbLoadingState = filterProps.filterState?.isFilterLoading === true && !_.isEmpty(currentFilterConfig?.selected_msn);
  const finPnrLoadingState = filterProps.filterState?.isFilterLoading === true && !_.isEmpty(currentFilterConfig?.selected_dataSource);
  const disableLowerFilters = _.isEmpty(currentFilterConfig?.aircraft_type);
  const disablePartLowerFilters = _.isEmpty(currentFilterConfig?.selected_dataSource);
  const disabledAcTye = (_.isEmpty(currentFilterConfig?.operator_name) && _.isEmpty(currentFilterConfig?.aircraft_type));
  const disabledAcSubType = (_.isEmpty(currentFilterConfig?.operator_name) || _.isEmpty(currentFilterConfig?.aircraft_type));
  const disableCheckerAcType = !_.isEmpty(currentFilterConfig?.operator_name) && !_.isEmpty(currentFilterConfig?.aircraft_family);
  const disableCheckerAcsubtype = disableCheckerAcType && currentFilterConfig?.aircraft_type;
  const disableCheckerLowerFilters = (disableCheckerAcsubtype && !_.isEmpty(currentFilterConfig?.selected_msn));
  const DataSourceFieldProps = {
    currentFilterConfig,
    selectedBrowserTab,
    filterProps,
    operatorValue,
    aircraftTypeValue,
    setCurrentFilterConfig,
    setAircraftTypeValue,
  };
  const finPnrPartFieldProps = {
    finPnrLoadingState,
    currentFilterConfig,
    filterProps,
    disablePartLowerFilters,
    setCurrentFilterConfig,
    setAircraftTypeValue,
    selectedBrowserTab,
  };
  const ModSbPartFieldProps = {
    modsbLoadingState,
    currentFilterConfig,
    filterProps,
    disableLowerFilters,
    setCurrentFilterConfig,
    setAircraftTypeValue,
    selectedBrowserTab,
  };
  const commonFieldProps = {
    currentFilterConfig,
    filterProps,
    selectedBrowserTab,
    disabledAcTye,
    disabledAcSubType,
    operatorValue,
    aircraftTypeValue,
    setCurrentFilterConfig,
    setOperatorValue,
    setAircraftTypeValue,
  };
  const checkerDetailsFieldProps = {
    filterProps,
    currentFilterConfig,
    setCurrentFilterConfig,
    disableCheckerAcType,
    disableCheckerAcsubtype,
    disableCheckerLowerFilters,
  };
  return (
    <Container data-testid="popup-filter-modal">
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={popupFilterState.modalToggle}
        className="modal-popup-container"
        id="browser-modal-popup"
      >
        <div className="modal-container">
          <Container>
            <Inline spacing="1-x">
              <Typography variant="medium" className="header-label">
                {POPUP_TITLE}
              </Typography>
              <LiveHelp className="help-icon" data-testid="filter-help-icon" onClick={() => { dispatch(setHelpModelToggle({ helpModalState: true, sourceOfHelpClick: filterProps.sourceOfHelpClick })); }} />
              {!filterProps.isInitialLoad && <Close className="modal-close-icon" onClick={handleClosePopup} data-testid="modal-close" />}
            </Inline>
            <Container>
              {filterProps.sourceTab === BROWSER && /* To avoid unwanted data call and UI loading */(
                <>
                  <CommanField commonFieldProps={commonFieldProps} />
                  {selectedBrowserTab === 0 && (<DataSourceField DataSourceFieldProps={DataSourceFieldProps} />)}
                  <Row className="warning-row">
                    <Typography variant="small" className="warning-text">{POPUP_WARNING_TEXT}</Typography>
                  </Row>
                  {selectedBrowserTab === 0 && (<PartField partFieldProps={finPnrPartFieldProps} />)}
                  {selectedBrowserTab === 1 && (<ModSbField ModSbFieldProps={ModSbPartFieldProps} />)}
                </>
              )}
              {filterProps.sourceTab === CHECKER && (<CheckerDetailsField checkerDetailsFieldProps={checkerDetailsFieldProps} />)}
            </Container>
            <Inline spacing="half-x" align="end" className="inline-button dataSelectionPrimary">
              <Button size="small" variant="secondary" className="reset-button" onClick={() => { setReset(true); setLocalStore('showFilteredTable', JSON.stringify(true)); }} data-testid="modal-reset">{RESET}</Button>
              <Button size="small" className={`${enableValidateButton(currentFilterConfig, filterProps?.sourceTab, checkerDetailsState.ifAircraftTypeChanged) ? 'validate-button-disabled' : 'validate-button'}`} disabled={enableValidateButton(currentFilterConfig, filterProps?.sourceTab)} onClick={handleValidate} data-testid="modal-validate">{VALIDATE}</Button>
            </Inline>
          </Container>

        </div>
      </Modal>
    </Container>
  );
};
export default ModalPopup;
