/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Row,
  Tooltip,
} from '@airbus/components-react';
import './ExportButton.scss';
import { Download } from '@airbus/icons/react';
import _ from 'lodash';
import posthog from 'posthog-js';
import { loadDataExportFile } from '../../../models/downloadStatusModel/downloadStatusThunk';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import {
  checkAcmFiltersSelected,
  exportDisableFunction,
} from '../../../utils/commonMethods/generalMethods';
import {
  ACM_EXPORT_TYPE,
  ACM_FILE_TYPE,
  CHECKER_EXPORT_TYPE,
  EXPORT_BUTTON_ARRAY,
  EXPORT_BUTTON_CREATE,
  EXPORT_TEXT,
  PART_EXPORT_TYPE,
  SB_EXPORT_TYPE,
  TOAST_TIMEOUT,
} from '../../../utils/constants';
import { prepareFiltersForPayload, restructureColumnFilters } from '../../../utils/commonUtil';
import { addToast, removeToast } from '../../../models/toastModel/toastSlice';
import DownloadModal from '../DownloadModal/DownloadModal';

interface ExportButtonprops {
  selectedExport: any;
  exportType: any;
  columnFilters?: any;
  columns?: any;
  isConcurrentToggle?: boolean;
  isSbStatusToggle?: boolean;
}

const defaultExportButtonProps = {
  columnFilters: {},
  columns: [],
  isConcurrentToggle: undefined,
  isSbStatusToggle: undefined,
};

export default function ExportButton(props: ExportButtonprops) {
  const {
    selectedExport, exportType, columnFilters, columns, isConcurrentToggle, isSbStatusToggle,
  } = props;
  const exportDispatch = useAppDispatch();
  const [isExportDisabled, setExportDisabled] = useState(false);
  const [exportDisable, setExportDisable] = useState(true);
  const [isExportClicked, setExportClicked] = useState(false);
  const [fileTypeValue, setFileTypeValue] = useState('');
  const { downloadStatus } = useAppSelector((state) => state.downloadStatus);
  const checkerDataTableState = useAppSelector((state) => state.displayCheckerDetailsTable);
  const partDataTableState = useAppSelector((state) => state.displayPartDataTable);
  const sbDataTableState = useAppSelector((state) => state.displaySbDataTable);
  const { userScope, exportDisableCount } = useAppSelector((state) => state.authEntrySlice);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);
  const [statusForRedownload, setStatusForRedownload] = useState('');
  const dataSourceState = useAppSelector((state) => state.popUpFilter?.part_filter_selection_configuration?.selected_dataSource);
  const options = EXPORT_BUTTON_ARRAY;
  const toastMsg = {
    toastLocation: 'global',
    isToastShown: true,
    toastMessage: 'File export is initiated successfully',
    toastVariantType: 'success' as Variant,
    customToastIcon: 'DOWNLOAD',
  };
  useMemo(() => {
    const exportStatus = downloadStatus.filter((item: any) => (item.export_type
      === CHECKER_EXPORT_TYPE));
    const status = exportStatus.every((file: any) => file.export_status === 'Success');
    if (status) {
      setExportDisabled(false);
    }
  }, [downloadStatus]);
  const handleExportClicked = () => {
    setExportClicked(false);
    setExportDisabled(false);
  };

  const exportEvent = (fileType: any) => {
    setFileTypeValue(fileType);
    const mutableColumnFilters = restructureColumnFilters(columnFilters, columns, isConcurrentToggle, isSbStatusToggle, exportType);
    const bodyPayload: BodyPayLoadExportFile = {
      file_type: fileType, // 'csv' or 'xlsx'
      export_type: exportType as string,
      operator_code_icao: [selectedExport.operator_name],
      operation: EXPORT_BUTTON_CREATE,
      filters: prepareFiltersForPayload(exportType, mutableColumnFilters, selectedExport, columns, isConcurrentToggle, isSbStatusToggle),
    };
    let checkFileForRedownload = false;
    let checkStatusForRedownload = '';
    if (exportType !== CHECKER_EXPORT_TYPE) {
      const redownloadArray = downloadStatus.map((item: any) => {
        return {
          file_type: item.file_name.split('.')[1],
          export_type: exportType as string,
          operator_code_icao: [selectedExport.operator_name],
          operation: EXPORT_BUTTON_CREATE,
          filters: item.filter_payload && JSON.parse(item.filter_payload),
        };
      });
      const checkFiltersRedownload = redownloadArray.find((item: any) => _.isEqual(item, bodyPayload));
      checkFileForRedownload = !_.isEmpty(checkFiltersRedownload);
      const matchedIndex = checkFiltersRedownload
        ? redownloadArray.indexOf(checkFiltersRedownload)
        : 0;
      checkStatusForRedownload = downloadStatus[matchedIndex]?.export_status;
      if (checkFileForRedownload && !isExportClicked) {
        setStatusForRedownload(checkStatusForRedownload);
        setExportClicked(true);
      }
    }
    if ((!checkFileForRedownload) || isExportClicked || checkStatusForRedownload === 'Failed') {
      exportDispatch(loadDataExportFile(bodyPayload)())
        .finally(() => {
          if (checkStatusForRedownload === 'Failed') {
            setExportDisabled(false);
          }
        });
      exportDispatch(addToast(toastMsg));
      setTimeout(() => {
        exportDispatch(removeToast(toastMsg));
      }, TOAST_TIMEOUT);
    }
  };
  const handlePosthogExport = () => {
    if (exportType === CHECKER_EXPORT_TYPE) {
      posthog.capture('CHECKER_EXPORT_BUTTON', {
        CHECKER_EXPORT: CHECKER_EXPORT_TYPE,
      });
    }
    if (exportType === SB_EXPORT_TYPE) {
      posthog.capture('SB_EXPORT_BUTTON', {
        BROWSER_EXPORT: SB_EXPORT_TYPE,
      });
    }
    if (exportType === PART_EXPORT_TYPE) {
      posthog.capture('PART_EXPORT_BUTTON', {
        PART_EXPORT: PART_EXPORT_TYPE,
        DATA_SOURCE: dataSourceState,
      });
    }
  };

  return (
    <div>
      {[ACM_EXPORT_TYPE, CHECKER_EXPORT_TYPE].includes(exportType)
        || (featureToggleConfig?.disableExportCsv === 'true' && [SB_EXPORT_TYPE, PART_EXPORT_TYPE].includes(exportType)) ? (
          <Row className="acm-row-button-container">
            <Col className={exportType === ACM_EXPORT_TYPE ? 'acm-button-container' : 'tabular-export-container'} xxs={4} md={4}>
              {exportDisableFunction(exportDisable, exportDisableCount, exportType, checkerDataTableState, partDataTableState, sbDataTableState)
                ? (
                  <Tooltip
                    className="export-tooltip"
                    placement="left"
                    label={`Export of results is disabled when results exceed
                    ${exportDisableCount.toLocaleString()}
                    rows. Please refine your data selection to avoid exceeding this limit.`}
                    data-testid="export-tooltip"
                  >
                    <Button
                      variant="primary"
                      icon={<Download />}
                      size="small"
                      className={`export-download-button-${exportType}`}
                      data-testid={`export-button-${exportType}`}
                      disabled={exportDisableFunction(exportDisable, exportDisableCount, exportType, checkerDataTableState, partDataTableState, sbDataTableState)}
                      onClick={() => { setExportDisable(false); }}
                    >
                      {EXPORT_TEXT}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    variant="primary"
                    icon={<Download />}
                    size={exportType === ACM_EXPORT_TYPE ? 'medium' : 'small'}
                    className={`export-download-button-${exportType}`}
                    data-testid={`export-download-button-${exportType}`}
                    disabled={(exportType === ACM_EXPORT_TYPE && checkAcmFiltersSelected(selectedExport, isExportDisabled, userScope)) || isExportDisabled}
                    onClick={() => {
                      setExportDisabled(true);
                      exportEvent(ACM_FILE_TYPE);
                      handlePosthogExport();
                    }}
                  >
                    {EXPORT_TEXT}
                  </Button>
                )}
            </Col>
          </Row>
        ) : (
          <Container data-testid="export-button" className="export-container">
            <Menu>
              <MenuButton
                disabled={isExportDisabled}
                size="small"
                variant="primary"
                icon={<Download />}
                className="export-button"
                data-testid="export"
                aria-haspopup="dialog"
              >
                {EXPORT_TEXT}
              </MenuButton>
              <MenuList className="export-menulist">
                <>
                  {options.map((option, index) => {
                    return (
                      <MenuItem
                        data-testid={option.value}
                        value={option.value}
                        onClick={(event: any) => exportEvent(event.target.textContent)}
                        key={`i${index + 1}`}
                      >
                        {option.value}
                      </MenuItem>
                    );
                  })}
                </>
              </MenuList>
            </Menu>
          </Container>
        )}
      {isExportClicked && (
        <DownloadModal
          isExportClicked
          handleExportClicked={handleExportClicked}
          checkStatusForRedownload={statusForRedownload}
          startExport={exportEvent}
          fileTypeValue={fileTypeValue}
          data-testid="download-modal-sb"
        />
      )}
    </div>
  );
}
ExportButton.defaultProps = defaultExportButtonProps;
