import { getFromLocalStore } from '../../utils/commonMethods/generalMethods';

export const GET_FILE_STATUS = 'getFileStatus';
export const FILE_STATUS_SLICE = 'nswcFileStatus';
export const GET_FILE_STATUS_ERROR = 'Failed to load file status';

export const FILE_STATUS_INITIAL_STATE: FileStausStateType = {
  isFileStatusLoading: false,
  selectedIcao: '',
  current_page: 1,
  next_page: 1,
  total_records: 0,
  records_offset: 0,
  paginationData: {
    rowPerPage: Number(getFromLocalStore('fliApiPaginationSize')),
    page: 1,
    start: 0,
    end: 0,
    selectedChunkData: [],
  },
  errorMessage: '',
  data: [],
};
