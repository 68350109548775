/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Breadcrumb, BreadcrumbItem, Card, Col, ComboBox, Container, Link,
  Row,
  Spinner,
  Typography,
} from '@airbus/components-react';
import React, { useEffect, useRef, useState } from 'react';
import './UploadFliHistory.scss';
import { Outlet } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  CHECKER,
  COMBOBOX_PLACEHOLDER, DEFAULT_FLI_PAGE, HOME, NO_PREVIOUS_RECORDS_FOUND, OPERATOR, UPLOAD_HISTORY,
} from '../../utils/constants';
import Table from '../Shared/Table/Table';
import { historyTableColumns } from './UploadFliHistoryTableStructure';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { getUploadHistoryFileStatus } from '../../models/nswc/fileStatusThunk';
import AppHeader from '../Shared/AppHeader/AppHeader';
import { LOCALES } from '../../assets/locale';
import { getCheckerFilters, handleUploadHistoryPageChange, upladHistoryOperatorList } from '../../utils/commonUtil';
import { setHistoryPagination, setSelectedIcao } from '../../models/nswc/fileStatusSlice';
import { getFromLocalStore } from '../../utils/commonMethods/generalMethods';

const UploadFliHistory: React.FC = function UploadFliHistory() {
  const [loading, setLoading] = useState(true);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const checkerState = useAppSelector((state) => state.checkerFleet);
  const nswcFileState = useAppSelector((state) => state.nswcFileStatus);
  const dispatcher = useAppDispatch();
  const paginationProps = {
    sourceTab: CHECKER,
    tableState: nswcFileState,
    dispatcher,
    handlePageChange: handleUploadHistoryPageChange,
    updateTableDataReducer: getUploadHistoryFileStatus,
    updatePaginationReducer: setHistoryPagination,
    rowPerPageOptions: [Number(getFromLocalStore('fliApiPaginationSize'))],
  };
  const checkerFilters = getCheckerFilters();
  useEffect(() => {
    // if no filters found, load the operator dropdowns first
    upladHistoryOperatorList(checkerState, checkerFilters, dispatcher);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // when operator list is loaded, select the first icao as selected icao
  useEffect(() => {
    const icaoList = checkerState.fleet_view_filters?.operators_list || [];
    if (!isEmpty(icaoList) && isEmpty(nswcFileState.selectedIcao)) dispatcher(setSelectedIcao({ icaoCode: icaoList[0] }));
  }, [checkerState.fleet_view_filters?.operators_list]);
  // make api call when selected icao changed
  useEffect(() => {
    if (!isEmpty(nswcFileState.selectedIcao)) {
      const payload = {
        icaoCode: nswcFileState.selectedIcao,
        pageNumber: Number(DEFAULT_FLI_PAGE),
      };
      dispatcher(getUploadHistoryFileStatus(payload)());
    }
  }, [nswcFileState.selectedIcao]);
  // Refresh every 20 seconds to fetch FLI file status

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setLoading(false);
      const payload = {
        icaoCode: nswcFileState.selectedIcao,
        offset: nswcFileState.paginationData.start,
        pageNumber: nswcFileState.current_page,
      };
      if (!isEmpty(nswcFileState.selectedIcao)) {
        dispatcher(getUploadHistoryFileStatus(payload)()).then(() => {
          setLoading(true); // Set loading to true after async operation completes
        });
      } else {
        setLoading(true); // If condition fails, immediately set loading to true
      }
    }, 20000);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }; // Cleanup interval on component unmount
  }, [nswcFileState.selectedIcao, nswcFileState.current_page, nswcFileState.paginationData.start]);
  // on change of icao from dropdown, change selected icao
  const handleIcaoSelected = (icaoValue: string | string[]) => {
    dispatcher(setSelectedIcao({ icaoCode: icaoValue }));
  };
  return (
    <Container className="bg-coolgrey-10 p-2 history-table-container">
      <div>
        <AppHeader appName={LOCALES} />
        <Outlet />
      </div>
      <Card className="history-card-container">
        <Breadcrumb className="breadcrumb-container-history">
          <BreadcrumbItem>
            <Link href="/" className="home-page">
              {HOME}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem current>{UPLOAD_HISTORY}</BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col xxs={8}>
            <Typography variant="large" className="text-content">{UPLOAD_HISTORY}</Typography>
          </Col>
          <Col xxs={1}>
            <Typography variant="small" className="operator-text">{OPERATOR}</Typography>
          </Col>
          <Col xxs={2} className="combo-operator-col">
            <ComboBox
              aria-label="Select Operator"
              placeholder={COMBOBOX_PLACEHOLDER}
              value={nswcFileState.selectedIcao?.toString()
                || checkerState.fleet_view_filter_selected?.selected_operator?.toString() || (checkerFilters?.operatorsList && checkerFilters?.operatorsList[0]?.toString())}
              data-testid="upload-history-operator-selector"
              options={checkerState.fleet_view_filters?.operators_list}
              getOptionLabel={(option) => option}
              getOptionValue={(option) => option}
              hideClear
              onChange={(value) => handleIcaoSelected(value)}
            />
          </Col>
        </Row>
      </Card>
      {nswcFileState.paginationData.selectedChunkData.length > 0 ? (
        <Container className="history-page-container checker-details-container" id="historyContainer">
          <Table
            data-testid="history-data-table"
            tableData={nswcFileState.paginationData.selectedChunkData}
            headerColumns={historyTableColumns}
            showChangeViewButton={false}
            showExportButton={false}
            showFilterButton={false}
            tableClassName="history-data-table"
            loadingupdate={nswcFileState.isFileStatusLoading && loading}
            showPagination
            paginationProps={paginationProps}
          />

        </Container>
      ) : (
        <Container className="upload-history-no-data">
          {nswcFileState.paginationData.selectedChunkData.length === 0 && nswcFileState.isFileStatusLoading === false ? (
            <Typography variant="medium" data-testid="nswc-text" className="nswc-text">{NO_PREVIOUS_RECORDS_FOUND}</Typography>
          ) : (
            <Spinner size="small" className="checker-nswc-spinner" />
          )}
        </Container>
      )}
    </Container>
  );
};
export default UploadFliHistory;
