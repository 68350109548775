/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import {
  ComboBox, Typography, Row, Col, Spinner,
} from '@airbus/components-react';
import '../ModalPopup.scss';
import _ from 'lodash';
import {
  COMBOBOX_PLACEHOLDER, OPERATOR, AIRCRAFT_TYPE, AIRCRAFT_SUB_TYPE, MSN, ATA, FIN, AIRCRAFT_FAMILY,
  MSNS_PER_PAGE,
} from '../../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../../store/hooksTypes';
import { rowsVisible } from '../../../../utils/commonMethods/modalPopupMethods';
import {
  loadCheckerFleetFinAndAtaDetails,
} from '../../../../models/checkerDetailsModel/checkerDetailsThunk';
import { HybridComboBox } from '../../../Shared/HybridComboBox/HybridComboBox';
import {
  fetchFleetViewDataForOperator,
  loadCheckerAcFamilyOptions, loadCheckerAcSubTypeOptions, loadCheckerAcTypeOptions, loadCheckerFinAndAtaOptions, loadCheckerMsnOptions,
  registerPostHogProperties,
} from '../../../../utils/commonUtil';
import {
  setCheckerDetailOperatorChanged, updateCheckerFiltersOptions,
} from '../../../../models/checkerDetailsModel/checkerDetailsSlice';
import { getFleetViewDataFromApi } from '../../../CheckerFleetFilter/Utils/FleetFilterUtils';

const CheckerDetailsField = function CheckerDetailsField(props: any) {
  const {
    checkerDetailsFieldProps,
  } = props;
  const [isLoadMsnCalled, setIsLoadMsnCalled] = useState<boolean>(false);
  const [viewCheckerData, setViewCheckerData] = useState<Array<any>>([]);
  const checkerState = useAppSelector((state) => state.checkerFleet);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(updateCheckerFiltersOptions({
      ...checkerDetailsFieldProps.filterProps.tableState.fleetDetailFilterOptions,
      operator_code_icao: checkerDetailsFieldProps.filterProps.checkerFleetState.fleet_view_filters?.operators_list,
    }));
    const selectedCheckerData = checkerState.data[checkerDetailsFieldProps.currentFilterConfig.operator_name] || [];
    if (!_.isEmpty(checkerDetailsFieldProps.currentFilterConfig.operator_name)) {
      getFleetViewDataFromApi([checkerDetailsFieldProps.currentFilterConfig.operator_name], dispatch);
    }
    if (!_.isEmpty(checkerDetailsFieldProps.currentFilterConfig.aircraft_family)) {
      loadCheckerAcTypeOptions(checkerDetailsFieldProps.currentFilterConfig.operator_name, checkerDetailsFieldProps.currentFilterConfig.aircraft_family, dispatch, selectedCheckerData);
    }
    if (!_.isEmpty(checkerDetailsFieldProps.currentFilterConfig?.aircraft_type)) {
      loadCheckerAcSubTypeOptions(checkerDetailsFieldProps.currentFilterConfig.operator_name, checkerDetailsFieldProps.currentFilterConfig?.aircraft_family, checkerDetailsFieldProps.currentFilterConfig?.aircraft_type, dispatch, selectedCheckerData);
      loadCheckerMsnOptions(checkerDetailsFieldProps.currentFilterConfig.operator_name, checkerDetailsFieldProps.currentFilterConfig?.aircraft_family, checkerDetailsFieldProps.currentFilterConfig?.aircraft_type, checkerDetailsFieldProps.currentFilterConfig?.aircraft_subtype, dispatch, selectedCheckerData);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(checkerDetailsFieldProps.currentFilterConfig.operator_name)) {
      const selectedCheckerData = checkerState.data[checkerDetailsFieldProps.currentFilterConfig.operator_name] || [];
      setViewCheckerData(selectedCheckerData);
      loadCheckerAcFamilyOptions(checkerDetailsFieldProps.currentFilterConfig.operator_name, dispatch, selectedCheckerData);
    }
  }, [checkerState.fleet_view_filters?.ac_family_list]);
  const handleOperatorSelection = (selectedOperator:any) => {
    registerPostHogProperties({
      ICAO_CODE: selectedOperator?.toString(),
    });
    // eslint-disable-next-line consistent-return
    checkerDetailsFieldProps.setCurrentFilterConfig(() => {
      return {
        operator_name: selectedOperator,
        aircraft_family: '',
        aircraft_type: '',
        aircraft_subtype: [],
        selected_msn: [],
        selected_fin: [],
        selected_ata: [],
      };
    });
  };

  const handleAcFamilySelection = (selectedAcFamily:any) => {
    checkerDetailsFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        aircraft_family: selectedAcFamily.value,
        aircraft_type: '',
        aircraft_subtype: [],
        selected_msn: [],
        selected_fin: [],
        selected_ata: [],
      };
    });
    if (selectedAcFamily) {
      loadCheckerAcTypeOptions(checkerDetailsFieldProps.currentFilterConfig.operator_name, selectedAcFamily.value, dispatch, viewCheckerData);
    }
    dispatch(setCheckerDetailOperatorChanged({ ...checkerDetailsFieldProps.filterProps.tableState, ifOperatorChanged: false, ifAircraftTypeChanged: false }));
  };

  const handleAcSelection = (selectedAc:Array<string>) => {
    checkerDetailsFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        aircraft_type: selectedAc,
        aircraft_subtype: [],
        selected_msn: [],
        selected_fin: [],
        selected_ata: [],
      };
    });
    if (selectedAc.length > 0) {
      loadCheckerAcSubTypeOptions(checkerDetailsFieldProps.currentFilterConfig.operator_name, checkerDetailsFieldProps.currentFilterConfig?.aircraft_family, selectedAc, dispatch, viewCheckerData);
      loadCheckerMsnOptions(checkerDetailsFieldProps.currentFilterConfig.operator_name, checkerDetailsFieldProps.currentFilterConfig?.aircraft_family, selectedAc, [], dispatch, viewCheckerData);
    }
  };

  const handleAcSubtypeSelection = (selectedAcSubtype:Array<string>) => {
    checkerDetailsFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        aircraft_subtype: selectedAcSubtype,
        selected_msn: [],
        selected_fin: [],
        selected_ata: [],
      };
    });
    loadCheckerMsnOptions(checkerDetailsFieldProps.currentFilterConfig.operator_name, checkerDetailsFieldProps.currentFilterConfig?.aircraft_family, checkerDetailsFieldProps.currentFilterConfig?.aircraft_type, selectedAcSubtype, dispatch, viewCheckerData);
  };

  const handleMsnSelection = (selectedMsn:any) => {
    const stateProps = isLoadMsnCalled
      ? { selected_msn: selectedMsn, selected_ata: [], selected_fin: [] }
      : { selected_msn: selectedMsn };

    checkerDetailsFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        ...stateProps,
      };
    });
    if (selectedMsn.length > 0) {
      loadCheckerFinAndAtaOptions(checkerDetailsFieldProps.currentFilterConfig.operator_name, checkerDetailsFieldProps.currentFilterConfig?.aircraft_type, selectedMsn, dispatch, loadCheckerFleetFinAndAtaDetails);
    }
  };

  const handleFinSelection = (selectFin:any) => {
    setIsLoadMsnCalled(true);
    checkerDetailsFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        selected_fin: selectFin,
      };
    });
  };

  const handleAtaSelection = (selectAta:any) => {
    checkerDetailsFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        selected_ata: selectAta,
      };
    });
  };

  useEffect(() => { setIsLoadMsnCalled(false); }, []);
  useEffect(() => {
    if (!_.isEmpty(checkerDetailsFieldProps.currentFilterConfig?.operator_name)) fetchFleetViewDataForOperator(checkerState, dispatch, [checkerDetailsFieldProps.currentFilterConfig?.operator_name]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkerDetailsFieldProps.currentFilterConfig?.operator_name]);
  return (
    <>
      <Row className="filter-row">
        <Col xxs={4}>
          <Typography variant="small" className="filter-label">
            {OPERATOR}
            <span className="required-fields">*</span>
          </Typography>
        </Col>
        <Col>
          <ComboBox
            aria-label="Select Operator"
            placeholder={COMBOBOX_PLACEHOLDER}
            value={checkerDetailsFieldProps.currentFilterConfig?.operator_name?.toString()}
            data-testid="checker-operator-selector"
            onChange={(value: any) => handleOperatorSelection(value)}
            options={checkerDetailsFieldProps.filterProps.checkerFleetState.fleet_view_filters.operators_list}
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            hideClear
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {AIRCRAFT_FAMILY}
            <span className="required-fields">*</span>
          </Typography>
        </Col>
        <Col xxs={1}>
          {checkerState.isDataLoading && <Spinner />}
        </Col>
        <Col>
          <ComboBox
            aria-label="Select AC Family"
            placeholder={COMBOBOX_PLACEHOLDER}
            value={checkerDetailsFieldProps.currentFilterConfig?.aircraft_family}
            data-testid="checker-ac-family-selector"
            onChange={(value: any) => handleAcFamilySelection(value)}
            options={checkerDetailsFieldProps.filterProps.tableState.fleetDetailFilterOptions.ac_family}
            disabled={!checkerDetailsFieldProps.currentFilterConfig?.operator_name?.toString()}
            hideClear
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={4}>
          <Typography variant="small" className="filter-label">
            {AIRCRAFT_TYPE}
            <span className="required-fields">*</span>
          </Typography>
        </Col>
        <Col>
          <ComboBox
            aria-label="Select ACType"
            placeholder={checkerDetailsFieldProps.currentFilterConfig?.aircraft_type?.length ? '' : COMBOBOX_PLACEHOLDER}
            value={checkerDetailsFieldProps.currentFilterConfig?.aircraft_type}
            data-testid="checker-ac-selector"
            onChange={(value: any) => handleAcSelection(value)}
            disabled={!(checkerDetailsFieldProps.disableCheckerAcType)}
            options={checkerDetailsFieldProps.filterProps.tableState.fleetDetailFilterOptions.ac_type}
            hideClear
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            multiple
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={4}>
          <Typography variant="small" className="filter-label">
            {AIRCRAFT_SUB_TYPE}
          </Typography>
        </Col>
        <Col>
          <ComboBox
            aria-label="Select AC SubType"
            multiple
            placeholder={checkerDetailsFieldProps.currentFilterConfig?.aircraft_subtype?.length ? '' : COMBOBOX_PLACEHOLDER}
            limitChips={3}
            value={checkerDetailsFieldProps.currentFilterConfig?.aircraft_subtype}
            data-testid="checker-ac-subtype-selector"
            onChange={(value: any) => handleAcSubtypeSelection(value)}
            options={checkerDetailsFieldProps.filterProps.tableState.fleetDetailFilterOptions.ac_sub_type}
            disabled={!(checkerDetailsFieldProps.disableCheckerAcsubtype) || checkerDetailsFieldProps.filterProps.tableState.isFilterDetailsAcSubTypeLoading || !checkerDetailsFieldProps.currentFilterConfig.aircraft_type[0]}
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            allowVirtualization
            virtualizationOptions={{
              overscanCount: 10,
              rowsVisible: rowsVisible(checkerDetailsFieldProps.filterProps.tableState.fleetDetailFilterOptions.ac_sub_type, 4),
              rowHeight: 45,
            }}
            hideClear
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={4}>
          <Typography variant="small" className="filter-label">
            {MSN}
            <span className="required-fields">*</span>
          </Typography>
        </Col>
        <Col>
          <HybridComboBox
            state={checkerDetailsFieldProps.filterProps.tableState}
            dispatcher={dispatch}
            isSearchDisabled={false}
            id="checkerDetailsMsn"
            data-testid="msn-selector"
            options={checkerDetailsFieldProps.filterProps.tableState.fleetDetailFilterOptions.id_aircraft_registration}
            disabled={!(checkerDetailsFieldProps.disableCheckerAcsubtype) || checkerDetailsFieldProps.filterProps.tableState.isFilterDetailsMsnLoading || !checkerDetailsFieldProps.currentFilterConfig.aircraft_type[0]}
            handleApply={handleMsnSelection}
            selectedOptions={checkerDetailsFieldProps.currentFilterConfig?.selected_msn}
            hybridComboClassName="hybrid-checker-details-msn-popover"
            placement="top"
            showApply={false}
            perPage={MSNS_PER_PAGE}
            isLazyloading
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {ATA}
          </Typography>
        </Col>
        <Col xxs={1}>
          {checkerDetailsFieldProps.filterProps.tableState.isFilterDetailsFinLoading && <Spinner />}
        </Col>
        <Col>
          <HybridComboBox
            state={checkerDetailsFieldProps.filterProps.tableState}
            dispatcher={dispatch}
            isSearchDisabled={false}
            id="checkerDetailsata"
            data-testid="ata-selector"
            options={checkerDetailsFieldProps.filterProps.tableState.fleetDetailFilterOptions.ata}
            disabled={!(checkerDetailsFieldProps.disableCheckerLowerFilters) || checkerDetailsFieldProps.filterProps.tableState.isFilterDetailsFinLoading}
            handleApply={handleAtaSelection}
            selectedOptions={checkerDetailsFieldProps.currentFilterConfig?.selected_ata}
            hybridComboClassName="hybrid-checker-details-msn-popover"
            placement="top"
            searchPattern="startsWith"
            showApply={false}
            isLazyloading
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {FIN}
          </Typography>
        </Col>
        <Col xxs={1}>
          {checkerDetailsFieldProps.filterProps.tableState.isFilterDetailsFinLoading && <Spinner />}
        </Col>
        <Col>
          <HybridComboBox
            state={checkerDetailsFieldProps.filterProps.tableState}
            dispatcher={dispatch}
            disabled={!(checkerDetailsFieldProps.disableCheckerLowerFilters) || checkerDetailsFieldProps.filterProps.tableState.isFilterDetailsFinLoading}
            selectedOptions={checkerDetailsFieldProps.currentFilterConfig?.selected_fin}
            isSearchDisabled={false}
            hybridComboClassName="hybrid-checker-details-msn-popover"
            placement="top"
            id="checker-fin-selector"
            data-testid="fin-selector"
            handleApply={handleFinSelection}
            isLazyloading
            showApply={false}
            options={checkerDetailsFieldProps.filterProps.tableState.fleetDetailFilterOptions.fin}
          />
        </Col>
      </Row>
    </>
  );
};
export default CheckerDetailsField;
