/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFromLocalStore } from '../../utils/commonMethods/generalMethods';
import { getPaginatedChunkData, rowPerPageFun } from '../../utils/commonUtil';
import {
  GET_FILE_STATUS_ERROR,
} from './constants';

export const fileStatusPending = (
  state: FileStausStateType,
) => ({
  ...state,
  isFileStatusLoading: true,
});

export const fileStatusRejected = (state: FileStausStateType) => {
  return {
    ...state,
    isFileStatusLoading: false,
    data: [],
    paginationData: {
      rowPerPage: Number(getFromLocalStore('fliApiPaginationSize')),
      page: 1,
      start: 0,
      end: 0,
      selectedChunkData: [],
    },
    errorMessage: GET_FILE_STATUS_ERROR,
  };
};

export const fileStatusFullfilled = (state: FileStausStateType, action: FileStausActionType) => {
  const fileStatusData = action.payload.data.map((item: any) => {
    const status = item.cmi_status ? `${item.fli_status} / ${item.cmi_status}` : item.fli_status;
    return {
      ...item,
      status,
    };
  });
  const calculatedPageNum = (action.payload.records_offset + rowPerPageFun(state)) / rowPerPageFun(state);
  const prevPage = state.paginationData.page;
  const chunkData = getPaginatedChunkData(
    calculatedPageNum,
    fileStatusData,
    rowPerPageFun(state),
    prevPage,
    action.payload.records_offset,
    state.total_records,
  );
  return {
    ...state,
    isFileStatusLoading: false,
    data: fileStatusData,
    total_records: action.payload.total_records,
    current_page: action.payload.current_page,
    next_page: action.payload.next_page,
    paginationData: {
      start: action.payload.records_offset,
      end: action.payload.records_offset + action.payload.data.length,
      rowPerPage: rowPerPageFun(state),
      page: calculatedPageNum,
      selectedChunkData: chunkData,
    },
  };
};
